@import '../../../variables.scss';

.propIcon {
  color: $primaryThemeDark;
  font-size: 20px;
}

.propIconWrapper {
  width: 20%;
}

.valuePropsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.valuePropsBoxAila1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  width: 20%;
  // border-radius: 4px;
  // border: 1px solid gray;
  cursor: default;
}

.valuePropsBoxAila {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  width: 15%;
  border: 1px solid gray;
  cursor: pointer;
}

.valuePropsBoxAila:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  background-color: $primaryThemeFadedDeep;
}

.valuePropsBoxCCFM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  cursor: pointer;
}

.valuePropsBoxCCFM:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  background-color: #c8c8c8;
}

.commImageWrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: $primaryThemeDark;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .valuePropsWrapper {
    display: flex;
    // flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .commImageWrapper {
    width: 175px;
    height: 175px;
  }
}
