@import "../../../variables.scss";

.modalContentWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.closeModalBtn {
  position:absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.sectionDivider {
  border-bottom: 1px solid $primaryColor;
  padding: 20px 30px;
  min-height: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.rowIconText {
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.rowIconText2 {
  color: white;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}

.rowIcon{
  flex-direction: column;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: $primaryThemeDark;
  display: flex;
  align-items: center;
  justify-content: center;
}

.columnIcon{
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: $primaryThemeDark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 30px;
  margin: 0 auto;
}

.rowColumnEntry {
  background-color: #e8e8e8;
  width: 45%;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  height: 200px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.lifestyleEntry {
  width: 30%;
  background-color: #e8e8e8;
  padding-left: 10px;
  padding-right: 10px;
  height: 240px;
  padding-bottom: 40px;
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.lifestyleText {
  height: 80%;
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  padding-bottom: 10px;
}
