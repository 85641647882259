@import "../../../variables.scss";

.patientStrip {
  height: 160px;
  min-width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  border-bottom: 1px solid $primaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sectionDivider {
  border-bottom: 1px solid $primaryColor;
  padding: 20px 30px;
  min-height: 300px;

  h4 {
    color: $primaryColor;
  }
}

.hoverChatWrapper {
  box-shadow: 5px 5px 5px 5px grey;
}

.closeModalBtn {
  position:absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}
