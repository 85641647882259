@import '../../../variables.scss';

p {
  font-family: 'Avenir';
  word-wrap: break-word;
  overflow: hidden;
}

.heading {
  font-size: 30px;
  margin: auto;
  text-align: center;
  font-weight: bold;
  color: $primaryThemeDark;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: center;
  margin: 0 auto;
}

.selectWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 3px 3px 3px 3px grey;
  width: 30%;
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: #e8faf6;
  // height: 700px;
}

.questionContainer {
  width: 70%;
}

.questionsFont {
  word-wrap: break-word;
  overflow: hidden;
  color: $primaryThemeDark;
  font-family: 'Avenir';
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.textInput1 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 3px;
  border-style: solid;
}

.textInput2 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: gray;
  border-radius: 3px;
  border-style: solid;
  font-family: 'Avenir';
  padding-left: 4px;
}

.newDiseaseTextBox {
  margin: 20px;
  width: 40%;
  border-width: 1px;
  border-color: gray;
  border-radius: 3px;
  font-family: 'Avenir';
  background-color: #20a892;
}

.rangeSlider {
  margin: 20;
  margin-top: 60;
  margin-bottom: 60;
  width: '60%';
}

.radioButton {
  background-color: #20a892;
}

.active {
  background-color: red !important;
}

input[type='radio'] {
  // border: 0px;
  // width: 100%;
  // height: 2em;
  visibility: collapse;
}

input[type='radio']:after {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: #e6f7e6;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(157, 194, 241);
}

input[type='radio']:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: $primaryColor;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $primaryThemeDark;
}

.listContainer {
  background-color: #e8faf6;
  width: 300px;
  padding: 0px;
}
.listItem {
  list-style-type: none;
  padding: 10px;
  margin: 2px;
  color: #637381;
}

.listItem:hover {
  background-color: #92dad4;
}

.deleteIcon {
  color: #20a892;
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 25px;
  color: rgb(23, 61, 68);
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button2 {
  background-color: white;
  border-color: $primaryColor;
}

.button2:hover {
  background-color: $primaryColorFaded;
  border-color: $primaryColor;
}

.button2:disabled {
  background-color: $primaryColorFaded;
}

.button2:active {
  background-color: $primaryColor;
}

.button2:focus {
  background-color: $primaryColorFaded;
}

.searchAdded {
  font-size: 18px;
  margin-top: 20;
  margin-left: 30;
  color: $primaryThemeDark;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.parentDiv {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 80%;
  // margin-bottom: 4vh;
  // margin-top: 4vh;
}

.childDiv {
  flex: 1 0 25%;
  // margin: -4vh 1vh;
  margin: auto 2vw;
}

.labelText {
  z-index: 10;
  font-size: large;
  // margin-top: -15%;
  word-wrap: break-word;
  width: 80%;
}

.imageIcon {
  width: 10vh;
  height: 10vh;
  align-self: flex-start;
}

.miniHeading {
  color: $primaryThemeDark;
  font-size: large;
  flex-wrap: wrap;
  text-align: left;
  margin: auto;
  z-index: 10;
}

.piChart {
  width: 14vw;
  height: 50vh;
}

.legendDiv {
  display: inline;
  font-weight: bold;
  justify-content: center;
  // margin: 45%;
}

.testTypeDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1%;
}

.covidInfection {
  font-size: 20;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .parentDiv {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-self: center;
    width: 90%;
    // margin-bottom: 4vh;
    // margin-top: 4vh;
  }

  .piChart {
    width: 95vw;
    height: 90vh;
  }

  .legendDiv {
    display: inline;
    font-weight: bold;
    justify-content: center;
    // margin: 20%;
  }

  .testTypeDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1%;
    width: 90%;
  }

  .covidInfection {
    width: 80vw;
    font-size: 20;
    flex-wrap: wrap;
    text-align: center;
    margin: auto;
    z-index: 10;
  }

  .childDiv {
    flex: 1 0 25%;
    // margin: -4vh 1vh;
    margin: 0% 20%;
  }
}
