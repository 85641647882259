@import "../../../variables.scss";

.propIcon {
  color: $primaryThemeDark;
  font-size: 16px;
}

.propIconWrapper{
  width: 20%;
}

.valuePropsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.valuePropsBoxAila {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  cursor: pointer;
  margin: 10px;
  height: 460px;
}

.valuePropsBoxAila:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  background-color: #c8c8c8;
}

.valuePropsBoxCCFM {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  cursor: pointer;
  margin: 10px;
  height: 460px;
}

.valuePropsBoxCCFM:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  background-color: #c8c8c8;
}

.commImageWrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: $primaryThemeDark;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 600px) {
  .valuePropsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .commImageWrapper {
    width: 175px;
    height: 175px;
  }

  .valuePropsBoxCCFM {
    width: 350px;
  }

  .valuePropsBoxAila {
    width: 350px;
  }

}
