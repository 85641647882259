@import "../../../variables.scss";

.rowIconText {
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.rowIcon{
  flex-direction: column;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: $primaryThemeDark;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entryRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
